import React from 'react';
import { IArticleComment } from '../../types/articlesTypes';
import default_avatar from '../../images/default-avatar.png';
import * as styles from './comment-item.module.scss';
type CommentItemProps = {
    comment: IArticleComment
}

const CommentItem: React.FC<CommentItemProps> = ({ comment }) => {
    return (
        <div className={styles.comment}>
            <img className={styles.commentAvatar} src={default_avatar} alt="" />
            <div className={styles.commentContent}>
                <span className={styles.commentContentName}>{comment.name} says:</span>
                <span className={styles.commentContentText}>{comment.comment}</span>
            </div>
        </div>
    )
}

export default CommentItem