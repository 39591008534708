import axios from 'axios';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import AlertPopup from '../components/alert-popup/AlertPopup';
import CommentsSection from '../components/comments-section/CommentsSection';
import Layout from '../components/layout/Layout';
import LeaveACommentForm from '../components/leave-a-comment-form/LeaveCommentForm';
import Spinner from '../components/spinner/Spinner';
import * as styles from '../pages/article/article.module.scss';
import { IArticleComment } from '../types/articlesTypes';

interface IArticlePageData {
    markdownRemark: {
        html: string,
        id: string,
        frontmatter: {
            title: string,
            subtitle?: string,
            featuredImage: string,
            author: string,
            date: string
        }
    }
}

type ArticleContainerProps = {
    data: IArticlePageData
}

type ArticlePageTemplateProps = {
    html: string,
    id: string,
    frontmatter: {
        title: string,
        subtitle?: string,
        featuredImage: string,
        author: string,
        date: string
    },
    comments: IArticleComment[],
    setResult: (value: string) => void,
    setIsFetching: (value: boolean) => void
}

export const ArticlePageTemplate: React.FC<ArticlePageTemplateProps> = ({ id, html, frontmatter, comments, setResult, setIsFetching }) => {
    return (
        <main>
            <section className={styles.main}>
                <div className={classNames(classNames, styles.mainContainer, 'wrapper')}>
                    <img className={styles.mainImage} src={frontmatter.featuredImage} alt="Article image" />
                    <h1 className={styles.mainTitle}>{frontmatter.title}</h1>
                    <div className={styles.mainInfo}>
                        <span className={styles.mainAuthor}>{frontmatter.author}{' '}</span>
                        <span className={styles.mainDate}>{frontmatter.date}</span>
                    </div>
                    {frontmatter.subtitle && <p className={styles.mainSubtitle}>{frontmatter.subtitle}</p>}
                    <div className={styles.articleContent} dangerouslySetInnerHTML={{ __html: html }} />
                    <CommentsSection comments={comments} />
                </div>
            </section>
            <LeaveACommentForm
                articleName={frontmatter.title}
                articleId={id}
                setResult={setResult}
                setIsFetching={setIsFetching}
            />
        </main>
    );
};

const ArticleContainer: React.FC<ArticleContainerProps> = ({ data }) => {
    const { markdownRemark: articleItem } = data;
    const [comments, setComments] = useState<any>([]);
    const [result, setResult] = useState<string>('');
    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`${process.env.GATSBY_GET_COMMENTS}&articleId=${articleItem.id}`, {})
            .then((res) => {
                setComments(res.data);
            });
    }, [])

    return (
        <Layout>
            {isFetching && <Spinner />}
            {result && <AlertPopup
                message={result}
                closePopup={() => setResult('')}
            />}
            <ArticlePageTemplate
                id={articleItem.id}
                html={articleItem.html}
                frontmatter={articleItem.frontmatter}
                comments={comments}
                setResult={setResult}
                setIsFetching={setIsFetching}
            />
        </Layout>
    )
}

export default ArticleContainer

export const articleByIdQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        featuredImage
        author
      }
    }
  }
`