import React from 'react';
import { IArticleComment } from '../../types/articlesTypes';
import CommentItem from '../comment-item/CommentItem';
import * as styles from './comments-section.module.scss';

type CommentsSectionProps = {
    comments: IArticleComment[]
}

const CommentsSection: React.FC<CommentsSectionProps> = ({ comments }) => {
    return (
        <section className={styles.commentsSection}>
            <h2 className={styles.commentsSectionTitle}>{comments.length} Response{comments.length === 1 ? '' : 's'}:</h2>
            {comments?.length > 0 && <div>
                {comments.map(el => <CommentItem key={el.RowKey} comment={el} />)}
            </div>}
        </section>
    )
}


export default CommentsSection;