import React from 'react';
import * as styles from './checkbox.module.scss';

type CheckboxProps = {
    label: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    checked: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
    label,
    onChange,
    checked
}) => {
    return (
        <div className={styles.checkbox}>
            <input type="checkbox" checked={checked} id='checkbox' onChange={onChange} />
            <label htmlFor="checkbox">{label}</label>
        </div>
    )
}

export default Checkbox