import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {EMAIL_REGEX, INVALID_EMAIL, INVALID_WEBSITE, REQUIRED_MESSAGE, WEBSITE_REGEX} from '../../utils/regex';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import Input from '../input/Input';
import Textarea from '../textarea/Textarea';
import * as styles from './leave-comment-form.module.scss';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type LeaveACommentFormProps = {
    articleName: string,
    articleId: string,
    setResult: (value: string) => void,
    setIsFetching: (value: boolean) => void
}
type LeaveACommentFields = {
    name: string,
    email: string,
    website: string,
    comment: string
}

const LeaveACommentForm: React.FC<LeaveACommentFormProps> = ({ articleName, articleId, setResult, setIsFetching }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isChecked, setChecked] = useState<boolean>(false);
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: {
            name: '',
            email: '',
            website: '',
            comment: ''
        },
        mode: 'all',
    });
    const { errors } = useFormState({ control });
    const onSubmit = async (values: LeaveACommentFields) => {
        if (!executeRecaptcha) {
            return
        }    
        setIsFetching(true);  
        const result = await executeRecaptcha('homepage')
        if (isChecked) {
            localStorage.setItem('comment-data', JSON.stringify({
                name: values.name,
                email: values.email,
                comment: values.comment
            }));
        }
        try {
            const res = await axios.post(
                `${process.env.GATSBY_POST_COMMENT}`, 
                { 
                    ...values, 
                    article: articleName,
                    articleId: articleId,
                    captcha: result
                }, 
                {}
            );
            if(res.data.code === 0) {
                reset();
            }
            setResult(res.data)
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            setResult('Ooops! Something went wrong :( Please, try again later.')
        }
    };

    useEffect(() => {
        if (localStorage.getItem('comment-data')) {
            const info = localStorage.getItem('comment-data')
            const userInfo = JSON.parse(info as string);
            Object.keys(userInfo).forEach(key => setValue(key as any, userInfo[key]))
        }
    }, []);

    return (
        <section className={styles.leaveComment}>
            <div className={classNames(classNames, styles.leaveCommentContainer, 'wrapper')}>
                <h2 className={styles.leaveCommentTitle}>Leave a Reply</h2>
                <p className={styles.leaveCommentSubtitle}>Your email address will not be published. Required fields are marked*</p>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.leaveCommentForm}>
                    <Controller
                        name={'comment'}
                        control={control}
                        rules={{
                            required: REQUIRED_MESSAGE,
                            maxLength: {
                                value: 2000,
                                message: 'Entered value should have max 2000 characters',
                            },
                        }}
                        render={({ field }) => (
                            <Textarea
                                name={'comment'}
                                label={'Comment *'}
                                placeholder={'Type Your Comment Here!'}
                                value={field.value}
                                onChange={field.onChange}
                                className={styles.leaveCommentFormField}
                                error={errors['comment']?.message}
                            />
                        )}
                    />
                    <Controller
                        name={'name'}
                        control={control}
                        rules={{
                            required: REQUIRED_MESSAGE,
                            maxLength: {
                                value: 100,
                                message: 'Entered value should have max 100 characters',
                            },
                        }}
                        render={({ field }) => (
                            <Input
                                label={"Name *"}
                                placeholder={'Steave'}
                                className={styles.leaveCommentFormField}
                                onChange={field.onChange}
                                value={field.value}
                                error={errors["name"]?.message}
                            />
                        )}
                    />
                    <Controller
                        name={'email'}
                        control={control}
                        rules={{
                            required: REQUIRED_MESSAGE,
                            pattern: { value: EMAIL_REGEX, message: INVALID_EMAIL }
                        }}
                        render={({ field }) => (
                            <Input
                                label={"Email *"}
                                placeholder={'steave@gmail.com'}
                                className={styles.leaveCommentFormField}
                                onChange={field.onChange}
                                value={field.value}
                                error={errors["email"]?.message}
                            />
                        )}
                    />
                    <Controller
                        name={'website'}
                        control={control}
                        rules={{
                            pattern: { value: WEBSITE_REGEX, message: INVALID_WEBSITE },
                        }}
                        render={({ field }) => (
                            <Input
                                label={"Website"}
                                placeholder={'my_website.com'}
                                className={styles.leaveCommentFormField}
                                onChange={field.onChange}
                                value={field.value}
                                error={errors["website"]?.message}
                            />
                        )}
                    />
                    <Checkbox
                        label="Save my name, email, and website in this browser for the next time I comment."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked)}
                        checked={isChecked}
                    />
                    <Button className={styles.leaveCommentFormButton} name='Post Comment' type={'submit'} />
                </form>
            </div>
        </section>
    )
}

export default LeaveACommentForm;